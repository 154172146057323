import React from 'react'
import img from './404.jpg'
import {
  css,
} from '@emotion/core'
const csscontainer = css `
  max-width: 1024px;
  width: 75%;
  margin: 20px auto;
  text-align:center;
`
const cssimagen = css`
  width: 50%;
  border-radius: 8px;
  margin: 0px auto;
  border: 1px solid #a0a0a0;
  box-shadow: 2px 2px 6px #808080;
`
const cssimagenwrapper = css`
  width: 100%;
  margin: 0px auto;
  text-align: center;
`
const css404=css`
  font-size: 128px;
  font-family: serif;
  margin: 0px auto;
  text-shadow: 2px 2px 6px #808080;
  color: black;
`
const NotFound = () => <div css={csscontainer}>
  <h1 css={css404}>404</h1>
  <div css={cssimagenwrapper}>
    <img css={cssimagen} src={img} alt=""/>
  </div>
</div>


export default NotFound
